/**
 * Checks if a node/element exists
 * @param {HTMLElement|Element} node - The node/element to check
 * @returns {Boolean}
 */
function isset(node){
    if (typeof(node) != 'undefined' && node != null) {
        return true;
    }else{
        return false;
    }
}


/**
 * Adds body class to check if JS is enabled
 * @returns {Boolean}
 */
function isJavascriptEnabled(){
    document.body.classList.add('js-enabled');
    return true;
}


/**
 * Adds body class to check if JS is enabled
 * @returns {Boolean}
 */
function prefersReducedMotion(){
    return window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
}


export {isset, isJavascriptEnabled, prefersReducedMotion};