/**
 * @file app.js is the entry point of the project
 * @author Finlay -- Radiator Digital
 */

/**
 * Imports
 */
    /* Import Libraries */
    import 'lazysizes';
    import Lenis from '@studio-freight/lenis';
    import Swiper from 'swiper';
    import { FreeMode } from 'swiper/modules';
    import { A11y } from 'swiper/modules';
    import { Keyboard } from 'swiper/modules';
    import f11y from 'f11y';

    /* Import Helpers */
    import { isset } from "./helpers/utils";
    import { isJavascriptEnabled } from "./helpers/utils";
    import { prefersReducedMotion } from "./helpers/utils";


/* Add JS class to body */
    isJavascriptEnabled();

/**
 * Lenis smooth scrolling
 */
    const lenis = new Lenis();

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);


/**
 * Gallery Row Sliders
 */
    const gallerySwiper = new Swiper('.gallery-swiper--js', {
        modules: [FreeMode, A11y, Keyboard],
        slidesPerView: "auto",
        freeMode: true,
        a11y: true,
        grabCursor: true,
        speed: 100,
        keyboard: true
    });


/**
 * Video Modals
 */
    const videoModals = document.querySelectorAll('.video-modal--js');
    videoModals.forEach(function(modal) {
        const videoModal = new f11y.Layer(modal, {
            onOpen: function(){
                lenis.stop();
                modal.querySelector('video').play();
            },
            onClose: function(){
                lenis.start();
                document.body.style.removeProperty('overflow');
                modal.querySelector('video').pause();
            },
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            closeOnBackgroundClick: true
        });
    });

    const autoplayVideos = document.querySelectorAll('.autoplay--js');
    autoplayVideos.forEach(function(videoContainer) {
        const video = videoContainer.querySelector('video');
        const switchMuteBtn = videoContainer.querySelector('button');

        switchMuteBtn.addEventListener('click', function(e){
            if(e.currentTarget.classList.contains('is-muted')){
                video.muted = false;
                video.volume = 0.6;
                e.currentTarget.classList.remove('is-muted');
            }else{
                video.muted = true;
                e.currentTarget.classList.add('is-muted');
            }
        });
    });


/** 
 *  Nav & Mobile Menu
*/
    const navMenu = document.querySelector(".nav-modal--js");
    const navLayer = new f11y.Layer(navMenu, 
        {
            onOpen: function(){
                lenis.stop();
                document.body.classList.add('nav-open');
            },
            onClose: function(){
                lenis.start();
                document.body.style.removeProperty('overflow');
                document.body.classList.remove('nav-open');
            },
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            closeOnBackgroundClick: true
        }
    );

    function checkLayerResize() {
        if(window.innerWidth < 1000){
            if(navLayer.isOpen()) return;
            navLayer.refresh();
        }else{
            navLayer.destroy();
        }
    }

    window.addEventListener('resize', checkLayerResize);
    document.addEventListener('DOMContentLoaded', checkLayerResize);


/** 
 *  Set Main/Footer Margin offset
*/
    function setFooterHeight(){
        const colophon = document.querySelector("#colophon");
        let footerHeight = colophon.offsetHeight;
        document.documentElement.style.setProperty('--footer-height', footerHeight + 'px');
    }
    document.addEventListener('DOMContentLoaded', setFooterHeight);
    window.addEventListener('resize', setFooterHeight);


/**
 * Rotation animations as user scrolls
 */
    function throttle(type, name) {
        let running = false;
        let func = function() {
            if (running) { return; }

            running = true;
            requestAnimationFrame(function() {
                window.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };

        window.addEventListener(type, func);
    };

    throttle("scroll", "optimizedScroll");

    window.addEventListener("optimizedScroll", function() {
        const flourishes = document.querySelectorAll('.flourish--js');
        flourishes.forEach(function(flourish) {
            let multiplier = flourish.dataset.multiplier;
            let img = flourish.querySelector('img');
            img.style.transform = "rotate(" + window.scrollY * multiplier + "deg)";
        });
    });


/**
 * Handle anchor links
 */
    function handleAnchorLinks(){
        const anchors = document.querySelectorAll('[data-anchor]');
        anchors.forEach(function(anchor) {
            const selector = anchor.dataset.anchor;
            const target = document.querySelector(selector);
            anchor.addEventListener("click", function(e) {
                e.preventDefault();
                
                if (prefersReducedMotion() === true) {
                    lenis.scrollTo(
                        target, 
                        {
                            'force': true,
                            'lerp': 0.05,
                            'offset': -50,
                            'immediate': true
                        }
                    );
                }else{
                    lenis.scrollTo(
                        target, 
                        {
                            'force': true,
                            'lerp': 0.05,
                            'offset': -50
                        }
                    );
                }
            });
            
        });
    }
    handleAnchorLinks();


/**
 * Intersection Observer for entrance animations
 */
    window.addEventListener('DOMContentLoaded', () => {
        let observer = new IntersectionObserver(
            function(entries, observer){ 
                entries.forEach(
                    function(entry){
                        if(entry.isIntersecting){
                            entry.target.classList.add("has-transformed");
                            observer.unobserve(entry.target);
                        }
                    }
                );
            },
            {rootMargin: "0px 0px -15% 0px"}
        );

        document.querySelectorAll('[data-transform]').forEach(
            function(el){ 
                observer.observe(el);
            }
        );
    });


/**
 * Automatic updating of copyright
 */
    function handleCopyrightYears(){
        const copyrightYearElms = document.querySelectorAll('[data-current-year]');

        const dateToday = new Date();
        const currentYear = dateToday.getFullYear();

        copyrightYearElms.forEach(function(yearElm) {
            yearElm.textContent = currentYear;
        });
    }
    handleCopyrightYears();